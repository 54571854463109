'use client';
import DownshiftUntyped from 'downshift8';
import React, { useId, useRef, useState } from 'react';
import DownShiftItemList from './ItemList';
import { PiCaretDownThin, PiCaretUpThin, PiX, PiXThin } from 'react-icons/pi';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  forwardRef,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text, useTheme, VisuallyHidden,
} from '@chakra-ui/react';
import { ComboboxItem } from './types';

const ForwardedInput = forwardRef((props, ref) => {
  return (
    <Input
      ref={ref}
      {...props}
      fontSize={props.fontSize}
      borderColor={props.invalid && "danger.100"}
      cursor="default"
      pr="2.5rem"
    />
  );
});

export type Size = "xs" | "sm" | "md" | "lg";

const Downshift = DownshiftUntyped<ComboboxItem>;

export type ItemsLoader = (abortController: AbortController | null, inputValue: string | null, pageNumber: number) => Promise<[ComboboxItem[], number]>;

export interface ComboboxProps {
  selectedItem?: ComboboxItem;
  id?: string;
  label?: string;
  onChange?: (ComboboxItem: any) => void;
  placeholder?: string;
  labelWidth?: string;
  loadSelectItems: ItemsLoader;
  onBlur?: () => void;
  invalid?: boolean;
  required?: boolean;
  size?: Size;
  allowClear?: boolean;
  disabled?: boolean;
  allowTextQuery?: boolean;
  initialIsOpen?: boolean;
  accentColor?: "tournesol" | "menthe" | "tuile" | "brand";
  fontSize?: string;
  isLabelVisuallyHidden?: boolean;
}

export default function Combobox({
  selectedItem,
  id,
  label,
  onChange,
  placeholder,
  labelWidth,
  loadSelectItems,
  onBlur,
  invalid,
  required,
  size = "md",
  allowClear = true,
  disabled,
  allowTextQuery = true,
  initialIsOpen,
  accentColor,
  fontSize,
  isLabelVisuallyHidden = false
  } : ComboboxProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const {maxFieldsWidth} = useTheme();
  const xsButtonWidth = "1.5em";
  const smButtonWidth = "2.25em";
  const mdButtonWidth = "2.75em";

  let downshiftId = `downshift-${useId()}`;
  if (id !== undefined) {
    downshiftId = id;
  }


  // Calculate selectedTextWidth in CSS:
  // width: calc(100% - caret button width - allowclear btn width)
  const selectedTextWidth = function (size : Size, allowClear : boolean) {
    let buttonWidth = mdButtonWidth;
    if (size === "xs") {
      buttonWidth = xsButtonWidth;
    }
    if (size === "sm") {
      buttonWidth = smButtonWidth;
    }
    if (allowClear === true) {
      return "calc(100% - (1.75 * " + buttonWidth + "))";
    }
    return "calc(100% - " + buttonWidth + ")";
  };

  return (
    <Downshift
      initialIsOpen={initialIsOpen}
      inputValue={inputValue}
      onInputValueChange={(inputValue : string) => {
        if (!allowTextQuery) {
          return;
        }

        setInputValue(inputValue);
      }}
      inputId={`${downshiftId}`}
      onSelect={(item, downshift) => {
        if (item !== selectedItem && onChange) {
          onChange(item);
        }
        inputRef?.current?.blur();
      }}
      itemToString={(item : ComboboxItem | null) => {
        if (!item) {
          return "";
        }

        return item.label;
      }}
      defaultHighlightedIndex={0}
      selectedItem={null}
    >
      {({
          getLabelProps,
          getInputProps,
          getItemProps,
          isOpen,
          closeMenu,
          openMenu,
          highlightedIndex,
          inputValue,
          clearItems,
          getMenuProps,
          getRootProps,
        }) => {

        const computeInputWrapperPaddingLeft = () => {
          if (size === "xs") {
            return 2;
          }
          if (size === "sm") {
            return 3;
          }
          return 4;
        };
        const computeInputLineHeight = () => {
          if (size === "xs") {
            return 7;
          }
          if (size === "sm") {
            return 8;
          }
          return 10;
        };
        const handleListTopPosition = () => {
          if (size === "sm") {
            return "calc(2rem + 1px)";
          }
          if (size === "md") {
            return "calc(2.5rem + 1px)";
          }
          return "calc(3rem + 1px)";
        }
        return (
          <FormControl
            {...getRootProps({ ref: rootRef }, { suppressRefError: true })}
            isRequired={required}
            maxWidth={maxFieldsWidth}
            fontSize="1em"
            aria-labelledby={id}
          >
            {label && (
              <>
                {isLabelVisuallyHidden ? (
                  <VisuallyHidden>
                    <FormLabel
                      {...getLabelProps()}
                      display="flex"
                      flexBasis={labelWidth ? labelWidth : "40%"}
                      flexShrink={0}
                      as="label"
                    >
                      <Box as="span" color={invalid ? "danger.100" : "black"}>
                        {label}
                      </Box>
                    </FormLabel>
                  </VisuallyHidden>
                ) : (
                  <FormLabel
                    {...getLabelProps()}
                    display="flex"
                    flexBasis={labelWidth ? labelWidth : "40%"}
                    flexShrink={0}
                  >
                    <Box as="span" color={invalid ? "danger.100" : "black"}>
                      {label}
                    </Box>
                  </FormLabel>
                )}
              </>
            )}
            <Box width="100%">
              <Flex width="100%" position="relative">
                <InputGroup size={size}>
                  {selectedItem && inputValue?.length === 0 && (
                    <Box
                      onClick={() => {
                        inputRef?.current?.focus();
                      }}
                      position="absolute"
                      pl={computeInputWrapperPaddingLeft()}
                      h="auto"
                      lineHeight="2.5em"
                      width={selectedTextWidth(size, allowClear)}
                      zIndex={3}
                      cursor={disabled ? "not-allowed" : "pointer"}
                      userSelect="none"
                      opacity={disabled ? "0.4" : "1"}
                    >
                      <Text
                        title={selectedItem.label}
                        noOfLines={1}
                        lineHeight={computeInputLineHeight()}
                        color="brand.300"
                        fontWeight="600"
                      >
                        {selectedItem.label}
                      </Text>
                    </Box>
                  )}

                  <ForwardedInput
                    {...getInputProps({
                      ref: inputRef,
                      fontSize: fontSize,
                      placeholder: selectedItem ? undefined : placeholder,
                      disabled,
                      isInvalid: invalid,
                      onFocus: (evt) => {
                        if (disabled) {
                          return;
                        }
                        openMenu();
                      },
                      onBlur: (evt) => {
                        if (inputRef) {
                          evt.preventDefault();
                          return;
                        }
                        closeMenu();

                        setInputValue("");
                        if (onBlur) {
                          onBlur();
                        }
                      },
                      onKeyDown: (evt: React.KeyboardEvent<HTMLInputElement>) => {
                        if (
                          (evt.key === "Backspace" || evt.key === "Delete") &&
                          !evt.currentTarget.value &&
                          allowClear &&
                          onChange
                        ) {
                          onChange(null);
                        }
                      },
                      })}
                      color={allowTextQuery ? undefined : "transparent"}
                      backgroundColor="white"
                      aria-labelledby={id}
                  />

                  {selectedItem && allowClear && (
                    <Flex
                      as={'button'}
                      type={'reset'}
                      onClick={(evt) => {
                        evt.preventDefault();
                        if (onChange) {
                          onChange(null);
                        }
                        clearItems();
                      }}
                      align="center"
                      justify="center"
                      aria-label="clear selection"
                      pointerEvents={disabled ? "none" : undefined}
                      opacity={disabled ? "0.4" : "1"}
                      aspectRatio="1 / 1"
                      rounded="full"
                      height="80%"
                      title="Supprimer la sélection"
                      _hover={{
                        border: "1px solid",
                        borderColor: "danger.100",
                        background: "white",
                        "& > svg": { color: "danger.100" }
                      }}
                      zIndex={3}
                      tabIndex={-1}
                      position="absolute"
                      top="50%"
                      right="2.5rem"
                      transform="translateY(-50%)"
                    >
                      <Icon as={PiX} color={'danger.100'} />
                    </Flex>
                  )}

                  <InputRightElement>
                    <Flex
                      as="button"
                      type={"button"}
                      onClick={(evt) => {
                        evt.preventDefault();
                        inputRef?.current?.focus();
                      }}
                      align="center"
                      justify="center"
                      aria-label="toggle menu"
                      border="1px solid"
                      transition='background-color .2s, color .2s'
                      borderColor={accentColor ? "black" : "transparent"}
                      background={accentColor ? accentColor + ".100" : "brand.100"}
                      pointerEvents={disabled ? "none" : undefined}
                      opacity={disabled ? "0.4" : "1"}
                      aspectRatio="1 / 1"
                      rounded="full"
                      height="80%"
                      _hover={{
                        borderColor: accentColor ? "black" : "brand.100",
                        background: "white",
                        "& > svg": {
                          color: accentColor ? "black" : "brand.100"
                        }
                      }}
                    >
                      <Icon as={isOpen ? PiCaretUpThin : PiCaretDownThin} color={(!accentColor || accentColor === 'brand') && "white"} boxSize='1.5rem' />
                    </Flex>
                  </InputRightElement>
                </InputGroup>

                {isOpen && (
                  <Box position="absolute" width="100%" top={handleListTopPosition()} left="0" zIndex="dropdown">
                    <DownShiftItemList
                    getItemProps={getItemProps}
                    highlightedIndex={highlightedIndex}
                    inputValue={inputValue}
                    getMenuProps={getMenuProps}
                    loadSelectItems={loadSelectItems}
                    size={size}
                    selectedItem={selectedItem}
                    accentColor={accentColor}
                  />
                  </Box>
                )}
              </Flex>
            </Box>
          </FormControl>
        );
      }}
    </Downshift>
  );
}